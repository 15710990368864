
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        



















































































































.mentor-card {
  text-align: left;
  height: 100%;
  width: 100%;
  &:hover {
    opacity: 0.5;
    text-decoration: none;
    & * {
      text-decoration: none;
    }
  }
}

.mentor-header {
  height: 50%;
}

.mentor-image {
  height: 100%;
  width: 100%;
  & img {
    object-fit: cover;
    object-position: center top;
    width: 100%;
    height: 100%;
    background-color: $gray-500;
  }
}

.mentor-body {
  height: 50%;
  padding: $text-xs $text-sm;
  background-color: $gray-100;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.mentor-name {
  font-weight: 700;
  font-size: $text-md;
  color: $accent-color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  padding: 2px 0 2px 0;
}

.mentor-title {
  line-height: 1.2;
  color: $gray-500;
  padding-bottom: $text-sm;
  font-weight: 400;
  font-size: $text-sm;
}

.mentor-icons {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  color: $accent-color;
  padding: 0 $text-xs;

  & * {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  a {
    color: $accent-color;
    &:hover {
      text-decoration: none;
      color: $gray-600;
      .icon {
        color: $accent-color;
        background-color: $gray-200;
      }
    }
  }
  .mentor-icon {
    &-image {
      @include border-radius(50%);
      background-color: $white;
      width: $text-lg;
      height: $text-lg;
      margin-bottom: 4px;
      .phone {
        padding: 4px 0 6px 0;
      }
    }

    &-value,
    &-label {
      font-size: $text-xs;
      line-height: 1.3;
    }

    &-value {
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
