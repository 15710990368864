
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        


































































































.blog {
  position: relative;
}

.blog-header,
.blog-content {
  @media (max-width: $screen-width-sm) {
    width: 100vw;
  }
  margin: auto;
}

.blog-header {
  height: 600px;
  margin-bottom: -200px;
  @media (max-width: $screen-width-sm) {
    height: 0px;
    margin-bottom: 0px;
  }
  .blog-image {
    @media (max-width: $screen-width-sm) {
      display: none;
    }
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.blog-content {
  background-color: $gray-100;
  padding-bottom: 68px;
}

.blog-main {
  position: relative;
  max-width: $screen-width-lg !important;
  padding: 0 $text-xl * 3;
  @media (max-width: $screen-width-sm) {
    max-width: 100vw;
    width: 100vw;
    padding: 0 $text-xl;
  }

  margin: auto;
  background-color: $white;
}

.blog-mentor-image {
  padding-top: 50px;
  padding-bottom: 10px;
  width: 200px;
  margin: auto;
  img {
    display: block;
    @include border-radius(50%);
    background-color: $gray-200;
    filter: grayscale(100%);
    width: 200px;
    height: 200px;
    object-fit: cover;
    object-position: top center;
  }
  a:hover {
    img {
      filter: none;
    }
  }
}

.blog-mentor-name {
  text-align: center;
  color: $accent-color;
  font-size: $text-sm;
  font-weight: 700;
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: $gray-600;
    }
  }
}

.blog-publication {
  text-align: center;
  color: $accent-color;
  font-size: $text-sm;
  padding-bottom: $text-sm;
}

.blog-title {
  font-size: $text-md;
  text-align: center;
  color: $gray-500;
  margin: 0 0;
  font-weight: 700;
  padding-bottom: 10px;
  h1,
  p {
    margin: 0;
  }
  margin-bottom: $text-md;
}

.blog-separator {
  margin-bottom: $text-md;
}

.blog-intro {
  @include head-body;
}

.blog-body {
  text-align: left;
  font-size: $text-sm;
  padding-bottom: $text-xl;

  & h1,
  & h2,
  & h3 {
    text-align: left;
    color: $accent-color;
    font-weight: 600;
    font-size: $text-sm;
    line-height: 1.5;
    padding: 0;
    padding-top: 4px;
    margin-bottom: 4px;
  }
  & blockquote {
    font-weight: 700;
    padding-left: $text-lg;
    padding-right: $text-lg * 2;
    padding-top: $text-sm;
    padding-bottom: $text-sm;
    margin-top: $text-md;
    margin-bottom: $text-md;
    border-left: 8px solid $accent-color;
  }
  a {
    color: $accent-color;
    &:hover {
      color: $accent-color;
    }
  }
}

.button-row {
  display: flex;
  padding-top: 68px;
  justify-content: center;

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid $white;
    padding: 0.25rem 0.5rem 0.4rem 0.5rem;
    text-decoration: none;
    font-size: $text-sm;
    text-align: center;
    transition: background 100ms ease-in-out, transform 50ms ease;
    background-color: $white;
    color: $accent-color;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      border: 1px solid $accent-color;
      color: $white;
      background-color: $accent-color;
    }
  }
}
