@mixin header {
  font-weight: 700;
  font-size: $text-xl;
}

@mixin intro {
  font-weight: 300;
  font-size: $text-md;
}

@mixin regular-text {
  font-weight: 400;
  font-size: $text-sm;
}

@mixin button {
  display: inline-block;
  font-size: $text-sm;
  line-height: 1.2;
  font-weight: inherit;
  color: $black;
  text-align: center;
  vertical-align: middle;
  cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
  user-select: none;
  background-color: transparent;
  border: 1px solid $black;
  border-radius: 0;
  padding: 10px $text-xl 12px $text-xl;

  &:hover {
    color: $black;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    color: $accent-color;
    background-color: $white;
    border-color: $white;
  }
}


@mixin head-title {
  line-height: 1;
  color: $accent-color;
  font-size: $text-xl;
  font-weight: 700;
}

@mixin head-body {
  margin-top: $text-md;
  font-weight: 300;
  font-size: $text-md;
  color: $gray-700;
  line-height: 1.3;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin upcase() {
  &:first-letter {
    text-transform: uppercase;
  }
}

@mixin truncate() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

@mixin linear-gradient-b($args...) {
  background-image: -o-linear-gradient($args);
  background-image: -moz-linear-gradient($args);
  background-image: -webkit-linear-gradient($args);
  background-image: linear-gradient($args);
}

@mixin aspect-ratio($ratio) {
  position: relative;

  > :first-child {
    width: 100%;
  }

  >img {
    height: auto;
  }

  &:before {
    content: "";
    display: block;
    padding-bottom: calc(100% / #{$ratio});
  }

  > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
