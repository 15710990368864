
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        























































































































.related {
  padding: $text-xl 0;
  background-color: $gray-400;
  margin: auto;

  @media (max-width: $screen-width-sm) {
    width: 100vw;
  }
}

h2 {
  text-align: center;
  font-size: $text-xl;
  padding: $text-xl 0;
}
