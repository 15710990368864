
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        








































a {
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }
}

.card {
  position: relative;
  background-color: $accent-color;
  color: $white;
  height: 100%;
  width: 100%;
  @media (max-width: $screen-width-sm) {
    width: 100vw;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    opacity: 0.5;
  }
}

.image {
  margin-top: $text-xs;
  margin-bottom: -6px;
  margin-left: 12px;
  svg {
    height: 160px;
  }
}

.logo {
  width: 80%;
  max-width: 300px;
  margin-bottom: 10px;
}

.card-body {
  position: absolute;
  width: 100%;
  bottom: $text-xs;
  left: 0;
  padding-bottom: 0;
  .title {
    text-align: center;
    color: $white;
    font-weight: 500;
    font-size: $text-md;
    line-height: 1.2;
    padding-top: 3px;
  }
}
