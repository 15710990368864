
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        






































































































































.head {
  text-align: center;
  margin: 40px 20%;
  p {
    @include head-body;
  }

  h1 {
    @include head-title;
  }
}

.filter {
  background-color: $gray-200;
  padding: 40px 10%;
  margin: 40px 0;
}

.blog {
  width: $screen-width-lg;
  margin: auto;
  margin-bottom: $text-lg;

  &-row1 {
    display: grid;
    grid-template:
      "post1  post1  post1  post2  post2  post2" 320px
      / 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: $text-lg;
    margin-bottom: $text-lg;
  }

  &-row2 {
    display: grid;
    grid-template:
      "post3  post3  post3  post3  post3  post3" 320px
      / 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: $text-md;
    margin-bottom: $text-lg;
  }

  &-row3 {
    display: grid;
    grid-template:
      "post4  post4  post4  search search search" 320px
      / 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: $text-lg;
    margin-bottom: $text-lg;
  }

  &-row4 {
    display: grid;
    grid-template:
      "enroll enroll post5  post5  post6  post6" 480px
      / 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: $text-lg;
    margin-bottom: $text-lg;
  }
}

.paging {
  padding-bottom: 20px;
}

.post {
  background-color: #edeae5;
}
.search {
  grid-area: search;
}

.enroll {
  grid-area: enroll;
  background-color: #c8c9ba;
}

.post1 {
  grid-area: post1;
}

.post2 {
  grid-area: post2;
}

.post3 {
  grid-area: post3;
}

.post4 {
  grid-area: post4;
}

.post5 {
  grid-area: post5;
}

.post6 {
  grid-area: post6;
}

@media (max-width: $screen-width-sm) and (max-width: $screen-width-lg) {
  .blog {
    width: 100vw;
  }
}

@media (max-width: $screen-width-sm) {
  .blog {
    width: 100vw;

    &-row1 {
      grid-template:
        "post1" 80vh
        "post2" 80vh;
    }

    &-row2 {
      grid-template: "post3" 80vh;
    }

    &-row3 {
      grid-template: "search" 60vh "post4" 80vh;
    }

    &-row4 {
      grid-template: "post5" 80vh "enroll" 60vh "post6" 80vh;
    }
  }
}
