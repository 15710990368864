
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        

















































.ask-question-card {
  position: relative;
  background-color: $gray-400;
  color: $accent-color;
  min-height: 260px;
  padding: 0 $text-xs;
  @media (max-width: $screen-width-sm) {
    width: 100vw;
    height: 90vh;
    margin: 0 auto;
  }

  a {
    display: flex;
    flex-direction: row;
    @media (max-width: $screen-width-sm) {
      flex-direction: column;
      height: 90%;
      justify-content: space-evenly;
    }

    color: inherit;
    text-decoration: none;

    &:hover {
      color: $white;
      h2 {
        color: $white;
      }
    }
  }

  &-header {
    margin: 0 auto;
    color: $white;

    &-image {
      height: 240px;
      @media (max-width: $screen-width-sm) {
        margin-top: $text-lg;
        height: 160px;
      }
      text-align: center;
      vertical-align: bottom;
      svg {
        height: 100%;
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: $text-lg;
    @media (max-width: $screen-width-sm) {
      padding: 0 $text-sm $text-sm $text-sm;
    }
  }

  &-title {
    h2 {
      font-size: $text-lg;
      line-height: 1.2;
    }
  }

  &-content {
    font-size: $text-sm;
    line-height: 1.2;
  }

  &-link {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
    svg {
      height: 80px;
    }
  }
}
