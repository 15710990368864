
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        

















































.card {
  height: 100%;
  width: 100%;
  @media (max-width: $screen-width-sm) {
    height: 100%;
    width: 100vw;
  }
  background-color: $gray-200;
  text-align: left;

  &:hover {
    opacity: 0.5;
    text-decoration: none;
    & * {
      text-decoration: none;
    }
  }
}
.card-1-1 {
  display: grid;
  grid-template:
    "header" 50%
    "body" 50%;
}

.card-full {
  display: block;
  position: relative;
}

.card-full .header {
  height: 100%;
}

.card-full img {
  height: 100%;
}

.card-full .card-body {
  position: absolute;
  bottom: $text-xs;
  left: 0;
  padding-bottom: 0;
}

.card-1-1 .header,
.card-7-9 .header {
  height: 100%;
}

.card-1-1 img,
.card-7-9 img {
  height: 100%;
}

.card-7-9 {
  display: grid;
  grid-template:
    "body" (7 / 16) * 100%
    "header" (9 / 16) * 100%;
}

@media (max-width: $screen-width-sm) {
  .card-1-1,
  .card-full,
  .card-7-9 {
    display: grid;
    grid-template:
      "header" 50%
      "body" 50%;
  }

  .card-1-1 .header,
  .card-full .header,
  .card-7-9 .header {
    height: 100%;
  }

  .card-1-1 img,
  .card-full img,
  .card-7-9 img {
    height: 100%;
  }
}

.title {
  color: $accent-color;
  font-weight: 500;
  font-size: $text-md;
  line-height: 1.2;
  padding-top: 3px;
}

.label {
  color: $white;
  background-color: $accent-color;
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 12px;
  font-size: $text-sm;
  z-index: 0;
}

.header {
  margin: 0px;
  position: relative;
  grid-area: header;
}

.card-body {
  padding: $text-md;
  padding-top: $text-md;
  grid-area: body;
}

.image {
  height: 100%;
  width: 100%;
}

.image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 1;
}

.author,
.views {
  font-size: $text-sm;
  display: inline-block;
  color: $gray-550;
}

.author {
  font-weight: bold;
  margin-right: 1ex;
}
