
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        








































.mentor-carousel {
  width: 100%;
  @media (max-width: $screen-width-sm) {
    display: block;
    &-card {
      max-width: 80vw;
      width: 80vw;
      height: 80vh;
      margin: 10vw;
    }
  }

  display: grid;
  grid-template-columns: repeat(3, minmax(31%, 1fr));
  grid-gap: $text-sm;
  &-card {
    height: 480px;
  }
}
