
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        








































.card {
  width: 100%;
  @media (max-width: $screen-width-sm) {
    width: 100vw;
  }

  background-color: $gray-400;
  color: $accent-color;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  padding-top: $text-xl;
  &:hover {
    & input {
      background-color: $gray-400;
    }
  }
}

.title {
  font-weight: 700;
  font-size: $text-xl;
  text-align: center;
  line-height: 1;
}

.body {
  text-align: left;
  font-size: $text-sm;
}
.input,
.button {
  width: 100%;
  margin-bottom: $text-xs;
}

button,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid $white;
  width: 100%;
  margin: 0;
  padding: 0.25rem 0.5rem 0.4rem 0.5rem;
  text-decoration: none;
  font-size: $text-sm;
  text-align: center;
  transition: background 100ms ease-in-out, transform 50ms ease;
}

input {
  background-color: #00000000;
  color: $white;

  &::placeholder {
    color: $white;
  }

  &:focus {
    outline: none;
    border: 1px solid white;

    &::placeholder {
      color: transparent;
    }
  }
}

button {
  background-color: $white;
  color: $accent-color;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid $accent-color;
    color: $white;
    background-color: $accent-color;
  }
}

.md {
  display: block;
  @media (max-width: $screen-width-sm) {
    display: none;
  }
}

.sm {
  &.button {
    @include button;
    display: none;
    @media (max-width: $screen-width-sm) {
      display: inline-block;
    }
    a {
      text-decoration: none;
      color: inherit;
      display: inline-block;
    }
    background-color: $white;
    color: $accent-color;
    border-color: $white;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      border: 1px solid $accent-color;
      color: $white;
      background-color: $accent-color;
    }
  }
}
