@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.card[data-v-3d6e2ae2] {
  height: 100%;
  width: 100%;
  background-color: #d8d6cd;
  text-align: left;
}
@media (max-width: 576px) {
.card[data-v-3d6e2ae2] {
      height: 100%;
      width: 100vw;
}
}
.card[data-v-3d6e2ae2]:hover {
    opacity: 0.5;
    text-decoration: none;
}
.card:hover *[data-v-3d6e2ae2] {
      text-decoration: none;
}
.card-1-1[data-v-3d6e2ae2] {
  display: grid;
  grid-template: "header" 50% "body" 50%;
}
.card-full[data-v-3d6e2ae2] {
  display: block;
  position: relative;
}
.card-full .header[data-v-3d6e2ae2] {
  height: 100%;
}
.card-full img[data-v-3d6e2ae2] {
  height: 100%;
}
.card-full .card-body[data-v-3d6e2ae2] {
  position: absolute;
  bottom: 14px;
  left: 0;
  padding-bottom: 0;
}
.card-1-1 .header[data-v-3d6e2ae2],
.card-7-9 .header[data-v-3d6e2ae2] {
  height: 100%;
}
.card-1-1 img[data-v-3d6e2ae2],
.card-7-9 img[data-v-3d6e2ae2] {
  height: 100%;
}
.card-7-9[data-v-3d6e2ae2] {
  display: grid;
  grid-template: "body" 43.75% "header" 56.25%;
}
@media (max-width: 576px) {
.card-1-1[data-v-3d6e2ae2],
  .card-full[data-v-3d6e2ae2],
  .card-7-9[data-v-3d6e2ae2] {
    display: grid;
    grid-template: "header" 50% "body" 50%;
}
.card-1-1 .header[data-v-3d6e2ae2],
  .card-full .header[data-v-3d6e2ae2],
  .card-7-9 .header[data-v-3d6e2ae2] {
    height: 100%;
}
.card-1-1 img[data-v-3d6e2ae2],
  .card-full img[data-v-3d6e2ae2],
  .card-7-9 img[data-v-3d6e2ae2] {
    height: 100%;
}
}
.title[data-v-3d6e2ae2] {
  color: #f3594c;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.2;
  padding-top: 3px;
}
.label[data-v-3d6e2ae2] {
  color: #fff;
  background-color: #f3594c;
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 12px;
  font-size: 19px;
  z-index: 0;
}
.header[data-v-3d6e2ae2] {
  margin: 0px;
  position: relative;
  grid-area: header;
}
.card-body[data-v-3d6e2ae2] {
  padding: 26px;
  padding-top: 26px;
  grid-area: body;
}
.image[data-v-3d6e2ae2] {
  height: 100%;
  width: 100%;
}
.image img[data-v-3d6e2ae2] {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
  z-index: 1;
}
.author[data-v-3d6e2ae2],
.views[data-v-3d6e2ae2] {
  font-size: 19px;
  display: inline-block;
  color: #97938c;
}
.author[data-v-3d6e2ae2] {
  font-weight: bold;
  margin-right: 1ex;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.card[data-v-63ccefce] {
  width: 100%;
  background-color: #c8c9ba;
  color: #f3594c;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px;
  padding-top: 44px;
}
@media (max-width: 576px) {
.card[data-v-63ccefce] {
      width: 100vw;
}
}
.card:hover input[data-v-63ccefce] {
    background-color: #c8c9ba;
}
.title[data-v-63ccefce] {
  font-weight: 700;
  font-size: 44px;
  text-align: center;
  line-height: 1;
}
.body[data-v-63ccefce] {
  text-align: left;
  font-size: 19px;
}
.input[data-v-63ccefce],
.button[data-v-63ccefce] {
  width: 100%;
  margin-bottom: 14px;
}
button[data-v-63ccefce],
input[data-v-63ccefce] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #fff;
  width: 100%;
  margin: 0;
  padding: 0.25rem 0.5rem 0.4rem 0.5rem;
  text-decoration: none;
  font-size: 19px;
  text-align: center;
  -webkit-transition: background 100ms ease-in-out, -webkit-transform 50ms ease;
  transition: background 100ms ease-in-out, -webkit-transform 50ms ease;
  transition: background 100ms ease-in-out, transform 50ms ease;
  transition: background 100ms ease-in-out, transform 50ms ease, -webkit-transform 50ms ease;
}
input[data-v-63ccefce] {
  background-color: #00000000;
  color: #fff;
}
input[data-v-63ccefce]::-webkit-input-placeholder {
    color: #fff;
}
input[data-v-63ccefce]::-moz-placeholder {
    color: #fff;
}
input[data-v-63ccefce]:-ms-input-placeholder {
    color: #fff;
}
input[data-v-63ccefce]::-ms-input-placeholder {
    color: #fff;
}
input[data-v-63ccefce]::placeholder {
    color: #fff;
}
input[data-v-63ccefce]:focus {
    outline: none;
    border: 1px solid white;
}
input[data-v-63ccefce]:focus::-webkit-input-placeholder {
      color: transparent;
}
input[data-v-63ccefce]:focus::-moz-placeholder {
      color: transparent;
}
input[data-v-63ccefce]:focus:-ms-input-placeholder {
      color: transparent;
}
input[data-v-63ccefce]:focus::-ms-input-placeholder {
      color: transparent;
}
input[data-v-63ccefce]:focus::placeholder {
      color: transparent;
}
button[data-v-63ccefce] {
  background-color: #fff;
  color: #f3594c;
  cursor: pointer;
}
button[data-v-63ccefce]:hover, button[data-v-63ccefce]:focus {
    outline: none;
    border: 1px solid #f3594c;
    color: #fff;
    background-color: #f3594c;
}
.md[data-v-63ccefce] {
  display: block;
}
@media (max-width: 576px) {
.md[data-v-63ccefce] {
      display: none;
}
}
.sm.button[data-v-63ccefce] {
  display: inline-block;
  font-size: 19px;
  line-height: 1.2;
  font-weight: inherit;
  color: #000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
  padding: 10px 44px 12px 44px;
  display: none;
  background-color: #fff;
  color: #f3594c;
  border-color: #fff;
  cursor: pointer;
}
.sm.button[data-v-63ccefce]:hover {
    color: #000;
    text-decoration: none;
}
.sm.button[data-v-63ccefce]:focus, .sm.button.focus[data-v-63ccefce] {
    outline: 0;
    color: #f3594c;
    background-color: #fff;
    border-color: #fff;
}
@media (max-width: 576px) {
.sm.button[data-v-63ccefce] {
      display: inline-block;
}
}
.sm.button a[data-v-63ccefce] {
    text-decoration: none;
    color: inherit;
    display: inline-block;
}
.sm.button[data-v-63ccefce]:hover, .sm.button[data-v-63ccefce]:focus {
    outline: none;
    border: 1px solid #f3594c;
    color: #fff;
    background-color: #f3594c;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
a[data-v-633d47d4] {
  color: white;
  text-decoration: none;
}
a[data-v-633d47d4]:hover {
    text-decoration: none !important;
}
.card[data-v-633d47d4] {
  position: relative;
  background-color: #f3594c;
  color: #fff;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 576px) {
.card[data-v-633d47d4] {
      width: 100vw;
}
}
.card[data-v-633d47d4]:hover {
    opacity: 0.5;
}
.image[data-v-633d47d4] {
  margin-top: 14px;
  margin-bottom: -6px;
  margin-left: 12px;
}
.image svg[data-v-633d47d4] {
    height: 160px;
}
.logo[data-v-633d47d4] {
  width: 80%;
  max-width: 300px;
  margin-bottom: 10px;
}
.card-body[data-v-633d47d4] {
  position: absolute;
  width: 100%;
  bottom: 14px;
  left: 0;
  padding-bottom: 0;
}
.card-body .title[data-v-633d47d4] {
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.2;
    padding-top: 3px;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.head[data-v-214eb7d5] {
  text-align: center;
  margin: 40px 20%;
}
.head p[data-v-214eb7d5] {
    margin-top: 26px;
    font-weight: 300;
    font-size: 26px;
    color: #414042;
    line-height: 1.3;
}
.head h1[data-v-214eb7d5] {
    line-height: 1;
    color: #f3594c;
    font-size: 44px;
    font-weight: 700;
}
.filter[data-v-214eb7d5] {
  background-color: #d8d6cd;
  padding: 40px 10%;
  margin: 40px 0;
}
.blog[data-v-214eb7d5] {
  width: 992px;
  margin: auto;
  margin-bottom: 36px;
}
.blog-row1[data-v-214eb7d5] {
    display: grid;
    grid-template: "post1  post1  post1  post2  post2  post2" 320px / 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 36px;
    margin-bottom: 36px;
}
.blog-row2[data-v-214eb7d5] {
    display: grid;
    grid-template: "post3  post3  post3  post3  post3  post3" 320px / 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 26px;
    margin-bottom: 36px;
}
.blog-row3[data-v-214eb7d5] {
    display: grid;
    grid-template: "post4  post4  post4  search search search" 320px / 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 36px;
    margin-bottom: 36px;
}
.blog-row4[data-v-214eb7d5] {
    display: grid;
    grid-template: "enroll enroll post5  post5  post6  post6" 480px / 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 36px;
    margin-bottom: 36px;
}
.paging[data-v-214eb7d5] {
  padding-bottom: 20px;
}
.post[data-v-214eb7d5] {
  background-color: #edeae5;
}
.search[data-v-214eb7d5] {
  grid-area: search;
}
.enroll[data-v-214eb7d5] {
  grid-area: enroll;
  background-color: #c8c9ba;
}
.post1[data-v-214eb7d5] {
  grid-area: post1;
}
.post2[data-v-214eb7d5] {
  grid-area: post2;
}
.post3[data-v-214eb7d5] {
  grid-area: post3;
}
.post4[data-v-214eb7d5] {
  grid-area: post4;
}
.post5[data-v-214eb7d5] {
  grid-area: post5;
}
.post6[data-v-214eb7d5] {
  grid-area: post6;
}
@media (max-width: 576px) and (max-width: 992px) {
.blog[data-v-214eb7d5] {
    width: 100vw;
}
}
@media (max-width: 576px) {
.blog[data-v-214eb7d5] {
    width: 100vw;
}
.blog-row1[data-v-214eb7d5] {
      grid-template: "post1" 80vh "post2" 80vh;
}
.blog-row2[data-v-214eb7d5] {
      grid-template: "post3" 80vh;
}
.blog-row3[data-v-214eb7d5] {
      grid-template: "search" 60vh "post4" 80vh;
}
.blog-row4[data-v-214eb7d5] {
      grid-template: "post5" 80vh "enroll" 60vh "post6" 80vh;
}
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.blog {
  position: relative;
}
.blog-header,
.blog-content {
  margin: auto;
}
@media (max-width: 576px) {
.blog-header,
    .blog-content {
      width: 100vw;
}
}
.blog-header {
  height: 600px;
  margin-bottom: -200px;
}
@media (max-width: 576px) {
.blog-header {
      height: 0px;
      margin-bottom: 0px;
}
}
.blog-header .blog-image {
    width: 100%;
    height: 100%;
}
@media (max-width: 576px) {
.blog-header .blog-image {
        display: none;
}
}
.blog-header .blog-image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
}
.blog-content {
  background-color: #edeae5;
  padding-bottom: 68px;
}
.blog-main {
  position: relative;
  max-width: 992px !important;
  padding: 0 132px;
  margin: auto;
  background-color: #fff;
}
@media (max-width: 576px) {
.blog-main {
      max-width: 100vw;
      width: 100vw;
      padding: 0 44px;
}
}
.blog-mentor-image {
  padding-top: 50px;
  padding-bottom: 10px;
  width: 200px;
  margin: auto;
}
.blog-mentor-image img {
    display: block;
    border-radius: 50%;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
    background-color: #d8d6cd;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    width: 200px;
    height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top center;
       object-position: top center;
}
.blog-mentor-image a:hover img {
    -webkit-filter: none;
            filter: none;
}
.blog-mentor-name {
  text-align: center;
  color: #f3594c;
  font-size: 19px;
  font-weight: 700;
}
.blog-mentor-name a {
    color: inherit;
    text-decoration: none;
}
.blog-mentor-name a:hover {
      color: #888894;
}
.blog-publication {
  text-align: center;
  color: #f3594c;
  font-size: 19px;
  padding-bottom: 19px;
}
.blog-title {
  font-size: 26px;
  text-align: center;
  color: #aca79e;
  margin: 0 0;
  font-weight: 700;
  padding-bottom: 10px;
  margin-bottom: 26px;
}
.blog-title h1,
  .blog-title p {
    margin: 0;
}
.blog-separator {
  margin-bottom: 26px;
}
.blog-intro {
  margin-top: 26px;
  font-weight: 300;
  font-size: 26px;
  color: #414042;
  line-height: 1.3;
}
.blog-body {
  text-align: left;
  font-size: 19px;
  padding-bottom: 44px;
}
.blog-body h1,
  .blog-body h2,
  .blog-body h3 {
    text-align: left;
    color: #f3594c;
    font-weight: 600;
    font-size: 19px;
    line-height: 1.5;
    padding: 0;
    padding-top: 4px;
    margin-bottom: 4px;
}
.blog-body blockquote {
    font-weight: 700;
    padding-left: 36px;
    padding-right: 72px;
    padding-top: 19px;
    padding-bottom: 19px;
    margin-top: 26px;
    margin-bottom: 26px;
    border-left: 8px solid #f3594c;
}
.blog-body a {
    color: #f3594c;
}
.blog-body a:hover {
      color: #f3594c;
}
.button-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 68px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.button-row button {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #fff;
    padding: 0.25rem 0.5rem 0.4rem 0.5rem;
    text-decoration: none;
    font-size: 19px;
    text-align: center;
    -webkit-transition: background 100ms ease-in-out, -webkit-transform 50ms ease;
    transition: background 100ms ease-in-out, -webkit-transform 50ms ease;
    transition: background 100ms ease-in-out, transform 50ms ease;
    transition: background 100ms ease-in-out, transform 50ms ease, -webkit-transform 50ms ease;
    background-color: #fff;
    color: #f3594c;
    cursor: pointer;
}
.button-row button:hover, .button-row button:focus {
      outline: none;
      border: 1px solid #f3594c;
      color: #fff;
      background-color: #f3594c;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.mentor-card[data-v-7cb62c3d] {
  text-align: left;
  height: 100%;
  width: 100%;
}
.mentor-card[data-v-7cb62c3d]:hover {
    opacity: 0.5;
    text-decoration: none;
}
.mentor-card:hover *[data-v-7cb62c3d] {
      text-decoration: none;
}
.mentor-header[data-v-7cb62c3d] {
  height: 50%;
}
.mentor-image[data-v-7cb62c3d] {
  height: 100%;
  width: 100%;
}
.mentor-image img[data-v-7cb62c3d] {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center top;
       object-position: center top;
    width: 100%;
    height: 100%;
    background-color: #aca79e;
}
.mentor-body[data-v-7cb62c3d] {
  height: 50%;
  padding: 14px 19px;
  background-color: #edeae5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.mentor-name[data-v-7cb62c3d] {
  font-weight: 700;
  font-size: 26px;
  color: #f3594c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  padding: 2px 0 2px 0;
}
.mentor-title[data-v-7cb62c3d] {
  line-height: 1.2;
  color: #aca79e;
  padding-bottom: 19px;
  font-weight: 400;
  font-size: 19px;
}
.mentor-icons[data-v-7cb62c3d] {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  color: #f3594c;
  padding: 0 14px;
}
.mentor-icons *[data-v-7cb62c3d] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.mentor-icons a[data-v-7cb62c3d] {
    color: #f3594c;
}
.mentor-icons a[data-v-7cb62c3d]:hover {
      text-decoration: none;
      color: #888894;
}
.mentor-icons a:hover .icon[data-v-7cb62c3d] {
        color: #f3594c;
        background-color: #d8d6cd;
}
.mentor-icons .mentor-icon-image[data-v-7cb62c3d] {
    border-radius: 50%;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
    background-color: #fff;
    width: 36px;
    height: 36px;
    margin-bottom: 4px;
}
.mentor-icons .mentor-icon-image .phone[data-v-7cb62c3d] {
      padding: 4px 0 6px 0;
}
.mentor-icons .mentor-icon-value[data-v-7cb62c3d], .mentor-icons .mentor-icon-label[data-v-7cb62c3d] {
    font-size: 14px;
    line-height: 1.3;
}
.mentor-icons .mentor-icon-value[data-v-7cb62c3d] {
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.ask-question-card[data-v-b431e254] {
  position: relative;
  background-color: #c8c9ba;
  color: #f3594c;
  min-height: 260px;
  padding: 0 14px;
}
@media (max-width: 576px) {
.ask-question-card[data-v-b431e254] {
      width: 100vw;
      height: 90vh;
      margin: 0 auto;
}
}
.ask-question-card a[data-v-b431e254] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    color: inherit;
    text-decoration: none;
}
@media (max-width: 576px) {
.ask-question-card a[data-v-b431e254] {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: 90%;
        -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
                justify-content: space-evenly;
}
}
.ask-question-card a[data-v-b431e254]:hover {
      color: #fff;
}
.ask-question-card a:hover h2[data-v-b431e254] {
        color: #fff;
}
.ask-question-card-header[data-v-b431e254] {
    margin: 0 auto;
    color: #fff;
}
.ask-question-card-header-image[data-v-b431e254] {
      height: 240px;
      text-align: center;
      vertical-align: bottom;
}
@media (max-width: 576px) {
.ask-question-card-header-image[data-v-b431e254] {
          margin-top: 36px;
          height: 160px;
}
}
.ask-question-card-header-image svg[data-v-b431e254] {
        height: 100%;
}
.ask-question-card-body[data-v-b431e254] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 36px;
}
@media (max-width: 576px) {
.ask-question-card-body[data-v-b431e254] {
        padding: 0 19px 19px 19px;
}
}
.ask-question-card-title h2[data-v-b431e254] {
    font-size: 36px;
    line-height: 1.2;
}
.ask-question-card-content[data-v-b431e254] {
    font-size: 19px;
    line-height: 1.2;
}
.ask-question-card-link[data-v-b431e254] {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
}
.ask-question-card-link svg[data-v-b431e254] {
      height: 80px;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.mentor-carousel[data-v-e8801968] {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(31%, 1fr));
  grid-gap: 19px;
}
@media (max-width: 576px) {
.mentor-carousel[data-v-e8801968] {
      display: block;
}
.mentor-carousel-card[data-v-e8801968] {
        max-width: 80vw;
        width: 80vw;
        height: 80vh;
        margin: 10vw;
}
}
.mentor-carousel-card[data-v-e8801968] {
    height: 480px;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.related[data-v-749dd402] {
  padding: 44px 0;
  background-color: #c8c9ba;
  margin: auto;
}
@media (max-width: 576px) {
.related[data-v-749dd402] {
      width: 100vw;
}
}
h2[data-v-749dd402] {
  text-align: center;
  font-size: 44px;
  padding: 44px 0;
}


/*# sourceMappingURL=blog.61d80f33.css.map*/